import React, { useEffect } from 'react';
import Button from '@mui/material/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import useGlobal from 'global-state/store';
import {
  Box,
  CircularProgress,
  Dialog, DialogActions, DialogContent, DialogTitle, Typography,
} from '@mui/material';
import { useSignIn } from 'useSignIn/SingInContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import ActivateCode from './account/ActivateCode';

export default function AlphaDialog({ alphaDialogOpen, setAlphaDialogOpen }) {
  const [globalState] = useGlobal();
  const navigate = useNavigate();
  const location = useLocation();
  const { openSignin } = useSignIn();
  const { currentUser: user, initializing } = useAuth();

  const isAlphaPath = !['/', '/account', '/contact'].includes(location.pathname);
  const isAlpha = globalState.roles.includes('alpha');
  const isLoading = ['initial', 'loading'].includes(globalState.userStatus);
  const isNotSignedIn = !initializing && !user;

  useEffect(() => {
    if (!alphaDialogOpen && isAlphaPath && !isLoading && !isAlpha) {
      if (isNotSignedIn) {
        openSignin();
      }
      setAlphaDialogOpen(true);
    } else if (location.pathname === '/' && alphaDialogOpen && !isLoading && isAlpha) {
      setAlphaDialogOpen(false);
      navigate('alpha');
    }
  }, [alphaDialogOpen, isAlpha, isAlphaPath, isLoading, isNotSignedIn,
    location.pathname, navigate, openSignin, setAlphaDialogOpen]);

  return (
    <Dialog open={alphaDialogOpen}>
      <DialogContent>
        <DialogTitle>Utiliser un code testeur alpha valide</DialogTitle>
        <Typography sx={{ mb: 3 }}>Si vous aviez déjà entré un code, merci de continuer.</Typography>
        {isLoading && (
          <Box sx={{
            width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center', my: 3,
          }}
          >
            <CircularProgress />
          </Box>
        )}
        {!isLoading && (
          <ActivateCode />
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="error"
          onClick={() => {
            setAlphaDialogOpen(false);
            navigate('/');
          }}
        >
          Quitter
        </Button>
        <Button
          color="primary"
          disabled={isLoading || !isAlpha}
          onClick={() => {
            setAlphaDialogOpen(false);
            if (location.pathname === '/') {
              navigate('alpha');
            }
          }}
        >
          Continuer
        </Button>
      </DialogActions>
    </Dialog>

  );
}

// FirestoreContext.js
import React, { createContext, useContext, useMemo } from 'react';
import {
  initializeFirestore, persistentLocalCache, persistentSingleTabManager,
} from 'firebase/firestore';

const FirestoreContext = createContext(null);

export function FirestoreProvider({ app, children }) {
  const savedSetting = localStorage.getItem('forceLongPolling');
  const forceLongPolling = savedSetting === 'true';
  let baseSettings = {};

  if (forceLongPolling) {
    baseSettings.experimentalForceLongPolling = true;
  } else {
    baseSettings.experimentalAutoDetectLongPolling = true;
  }

  // Initialize Firestore with custom settings if not already initialized
  baseSettings = {
    ...baseSettings,
    localCache: persistentLocalCache({
      tabManager: persistentSingleTabManager({
        experimentalForceOwningTab: true,
        // forceOwnership for web worker
        // eslint-disable-next-line no-undef
        forceOwnership: !globalThis.localStorage,
      }),
    }),
  };

  const fsSettings = (process.env.NODE_ENV === 'development')
    ? {
      ...baseSettings,
      host: 'localhost:4401',
      ssl: false,
    } : baseSettings;

  const firestore = initializeFirestore(app, fsSettings);

  const value = useMemo(() => firestore, [firestore]);

  return (
    <FirestoreContext.Provider value={value}>
      {children}
    </FirestoreContext.Provider>
  );
}

export const useFirestore = () => useContext(FirestoreContext);

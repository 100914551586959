import { Box, Typography } from '@mui/material';
import LoadingPage from 'components/LoadingPage';
import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import TaskAltIcon from '@mui/icons-material/TaskAlt';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { useFirestore } from 'firebaseHooks/FirestoreContext';

export default function QRCodes() {
  const { qrCodeId } = useParams();
  const [qrcodeData, setQrcodeData] = useState(null);
  const db = useFirestore();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchQrcodeData = async () => {
      const qrcodeDoc = await getDoc(doc(db, 'qrcodes', qrCodeId));

      if (qrcodeDoc.exists) {
        console.log(qrcodeDoc.data());
        setQrcodeData(qrcodeDoc.data());
      } else {
        setQrcodeData(null);
      }
      setLoading(false);
    };

    fetchQrcodeData();
  }, [db, qrCodeId]);

  if (loading) return <LoadingPage />;

  return (
    <div>
      {
        qrcodeData ? (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
          >
            <TaskAltIcon sx={{ fontSize: 80 }} color="success" />
            <Typography variant="h4">{t('qrcodes.found')}</Typography>
            <Typography variant="h6">
              {t('qrcodes.text', {
                seller: qrcodeData.sellerName,
                acquirer: qrcodeData.buyerName,
                vehicle: qrcodeData.vehicleModel,
                date: new Date(qrcodeData.contractCreationDate.seconds * 1000).toLocaleDateString(),
              })}
            </Typography>
          </Box>
        ) : (
          <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 3,
          }}
          >
            <ErrorOutlineIcon sx={{ fontSize: 80 }} color="error" />
            <Typography variant="h4">{t('qrcodes.not_found')}</Typography>
          </Box>
        )
            }
    </div>
  );
}

import React, {
  useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { Box, CircularProgress, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc } from 'firebase/firestore';
import useMenu from 'menu-actions/useMenu';
import { useFirestore } from 'firebaseHooks/FirestoreContext';

export default function GeneratePDFSuccess() {
  const navigate = useNavigate();
  const firestore = useFirestore();
  const { t } = useTranslation();
  const { setreturnAction } = useMenu();

  useEffect(() => {
    // eslint-disable-next-line no-promise-executor-return
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    async function goToContract(uid) {
      // make sure the stripe webhook had a chance to work before continuing
      await await sleep(5000);

      const autonoomContractDocRef = doc(firestore, 'autonoomContracts', contractId);
      const autonoomContractDoc = await getDoc(autonoomContractDocRef);
      const autonoomContract = autonoomContractDoc.data();

      const storageDocPath = 'gs://autonoom-ed849.appspot.com/autonoomContracts/'
        + `${contractId}/contrat-autonoom.pdf`;
      navigate('/contractPdf', {
        replace: true,
        state: {
          contract: autonoomContract,
          storageDocPath,
        },
      });
    }

    const queryParameters = new URLSearchParams(window.location.search);
    const success = queryParameters.get('success');
    const contractId = queryParameters.get('contractId');

    setreturnAction(() => () => navigate('/myContracts'));
    if (success === 'true' && contractId) {
      goToContract(contractId);
    }
  }, [firestore, navigate, setreturnAction]);

  return (
    <Box
      sx={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 3,
      }}
    >
      <Typography variant="h6">{t('result_contract_pdf.success')}</Typography>
      <Typography>{t('wait')}</Typography>
      <CircularProgress />
    </Box>
  );
}

import * as React from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { getAuth, signOut } from 'firebase/auth';
import ExitToApp from '@mui/icons-material/ExitToApp';
import Paper from '@mui/material/Paper';
import {
  Link as MUILink,
} from '@mui/material';
import { useNavigate, Link } from 'react-router-dom';
import { AccountCircle } from '@mui/icons-material';
import { useSignIn } from 'useSignIn/SingInContext';
import useGlobal from 'global-state/store';
import { useAuth } from 'firebaseHooks/AuthContext';
import ActivateCode from './ActivateCode';

export default function MyAccount() {
  const { currentUser: user } = useAuth();
  const { t } = useTranslation();
  const { openSignin } = useSignIn();

  if (!user) {
    return (
      <Button
        variant="outlined"
        color="inherit"
        size="small"
        component={Link}
        onClick={() => openSignin()}
        startIcon={<AccountCircle />}
      >
        {t('signin.login')}
      </Button>
    );
  }

  return <MyAccountContent user={user} />;
}

function MyAccountContent({ user }) {
  const { t } = useTranslation();
  const auth = getAuth();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();

  function email() {
    return user?.email;
  }

  const logout = () => {
    signOut(auth).then(() => {
      globalActions.setUserStatus('initial');
      navigate('/');
    });
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: 3,
        backgroundColor: 'lightGray.main',
        width: '100vw',
        minHeight: '80vh',
        mt: { xs: -10, md: -12 },
        pt: { xs: 10, md: 12 },
        px: 1,
      }}
    >
      <Paper
        elevation={0}
        sx={{
          p: 3,
          width: { xs: '100%', md: 600 },
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          mt: 5,
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'center',
            gap: 1,
            mb: 3,
          }}
        >
          {email()}
        </Box>

        <Button
          variant="outlined"
          color="inherit"
          sx={{ color: 'red' }}
          size="large"
          onClick={logout}
          startIcon={<ExitToApp />}
        >
          {t('logout')}
        </Button>

        <MUILink
          component={Link}
          to="/contact"
          color="primary"
          sx={{ fontWeight: 'bold' }}
        >
          contact@autonoom.fr
        </MUILink>
      </Paper>
      <Paper
        elevation={0}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          gap: 1,
          padding: 3,
          width: { xs: '100%', md: 600 },
        }}
      >
        <ActivateCode />
      </Paper>

    </Box>
  );
}

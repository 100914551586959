import React, { useEffect } from 'react';
import Box from '@mui/material/Box';
import useGlobal from 'global-state/store';
import { useNavigate } from 'react-router-dom';
import ActivateCodeToUser from './ActivateCodeToUser';
import CreateCode from './CreateCode';
import Codes from './Codes';
import ValidateUserEmail from './ValidateUserEmail';
import Contracts from './Contracts';

export default function Admin(props) {
  const [globalState] = useGlobal();
  const navigate = useNavigate();

  const isAdmin = globalState.roles.includes('admin');
  const isLoading = ['initial', 'loading'].includes(globalState.userStatus);

  useEffect(() => {
    if (!isLoading && !isAdmin) {
      navigate('/');
    }
  }, [globalState.roles, isAdmin, isLoading, navigate]);

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      gap: 3,
      width: '100%',
      textAlign: 'left',
    }}
    >
      <Box
        sx={{
          width: '100%',
          display: 'flex',
          flexDirection: 'row',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'center',
          gap: 3,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            alignItems: 'flex-start',
            justifyContent: 'center',
            gap: 3,
          }}
        >
          <ActivateCodeToUser />
          <ValidateUserEmail />
        </Box>
        <CreateCode />
      </Box>
      <Codes />
      <Contracts />
    </Box>
  );
}

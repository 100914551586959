import React, { useCallback, useEffect } from 'react';
import {
  Dialog, DialogContent,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  useNavigate,
  useLocation,
  Link,
} from 'react-router-dom';
import useGlobal from 'global-state/store';
import {
  GoogleAuthProvider, signInWithPopup, signInWithEmailAndPassword,
  createUserWithEmailAndPassword, sendEmailVerification,
  getAuth,
} from 'firebase/auth';
import { logEvent } from 'firebase/analytics';
import { useSignIn } from 'useSignIn/SingInContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useAnalytics } from 'firebaseHooks/AnalyticsContext';
import { getApp } from 'firebase/app';
import SignIn from './SignIn';

export default function SignInDialog() {
  const { t } = useTranslation();
  const { currentUser: user } = useAuth();
  const navigate = useNavigate();
  const [, globalActions] = useGlobal();
  const location = useLocation();
  const app = getApp();
  const auth = getAuth(app);
  const { analytics } = useAnalytics();
  const provider = new GoogleAuthProvider();
  const {
    signInDialogOpen, setSignInDialogOpen, signinCallback,
  } = useSignIn();

  const ourSignInWithPopup = () => signInWithPopup(auth, provider);

  const closeSignin = useCallback(() => {
    setSignInDialogOpen(false);
  }, [setSignInDialogOpen]);

  useEffect(() => {
    if (signInDialogOpen && user?.uid) {
      closeSignin();
      signinCallback();
    }
  }, [closeSignin, user?.uid, signInDialogOpen, signinCallback]);

  return (
    <Dialog
      fullWidth
      open={signInDialogOpen}
      onClose={() => setSignInDialogOpen(false)}
    >
      <DialogContent sx={{ px: { xs: 1, sm: 3 }, py: 3, backgroundColor: 'lightGray.main' }}>
        <SignIn
          t={t}
          globalActions={globalActions}
          navigate={navigate}
          location={location}
          Link={Link}
          auth={auth}
          analytics={analytics}
          gtcuCookieName="agreed-to-general-terms-digitank-tanker-trucks"
          ourSignInWithPopup={ourSignInWithPopup}
          signInWithEmailAndPassword={signInWithEmailAndPassword}
          createUserWithEmailAndPassword={createUserWithEmailAndPassword}
          logEvent={logEvent}
          sendEmailVerification={sendEmailVerification}
          signinCallback={() => {
            globalActions.setUserStatus('initial');
            signinCallback();
          }}
          closeSignin={closeSignin}
        />
      </DialogContent>
    </Dialog>
  );
}

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import useGlobal from 'global-state/store';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import {
  doc, setDoc, Timestamp,
} from 'firebase/firestore';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import moment from 'moment';
import { useFirestore } from 'firebaseHooks/FirestoreContext';

export default function CreateCode() {
  const { t } = useTranslation();
  const [activating, setactivating] = useState(false);
  const [, globalActions] = useGlobal();
  const codeTypeData = {
    AlphaCode: {
      id: 'string',
      codeExpiryDate: 'date',
      activationsLeft: 'number',
      activationsUsed: 'number',
    },
  };
  const [codeType, setcodeType] = useState('AlphaCode');
  const db = useFirestore();

  const initialValues = (typeName) => {
    const initVals = { ...codeTypeData[typeName] };
    Object.keys(codeTypeData[typeName]).forEach((key) => {
      initVals[key] = '';
    });
    return initVals;
  };

  return (
    <Formik
      initialValues={initialValues(codeType)}
      validate={(values) => {
        const errors = {};
        Object.entries(values).forEach((keyValue) => {
          if (keyValue[1] === undefined || keyValue[1] === '') {
            errors[keyValue[0]] = t('required');
          }
        });
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setactivating(true);
          await setDoc(
            doc(db, 'availableCodes', values.id),
            { type: codeType, ...values },
          );
          globalActions.setSnackbarMessage({ message: 'Succès', severity: 'success', displayDuration: 2000 });
        } catch (error) {
          globalActions.setSnackbarMessage({ message: t('unexpected_error'), severity: 'error' });
        } finally {
          setSubmitting(false);
          setactivating(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
        setFieldValue,
        resetForm,
      }) => (
        <Paper
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
            padding: 3,
            width: '95%',
          }}
          onSubmit={handleSubmit}
        >
          <Typography component="span" variant="h6">
            Créer un code
          </Typography>
          <FormControl variant="outlined">
            <Select
              labelId="type-select"
              id="type-select"
              value={codeType}
              onChange={(e) => {
                setcodeType(e.target.value);
                resetForm({ values: initialValues(e.target.value) });
              }}
            >
              {Object.keys(codeTypeData).map((key) => (
                <MenuItem key={key} value={key}>{key}</MenuItem>
              ))}
            </Select>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterMoment}>
            <Box
              sx={{
                mt: 2,
                display: 'flex',
                flexDirection: 'row',
                flexWrap: 'wrap',
                justifyContent: 'center',
                alignItems: 'flex-start',
                gap: 2,
              }}
            >
              {Object.entries(codeTypeData[codeType]).map((nameAndType) => (
                <InputForType
                  key={nameAndType[0]}
                  name={nameAndType[0]}
                  type={nameAndType[1]}
                  handleChange={handleChange}
                  setFieldValue={setFieldValue}
                  errors={errors}
                  touched={touched}
                  values={values}
                />
              ))}
            </Box>
          </LocalizationProvider>
          <LoadingButton
            size="medium"
            loading={activating}
            variant="contained"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            Créer
          </LoadingButton>
        </Paper>
      )}
    </Formik>
  );
}

function InputForType(props) {
  const {
    name, type, handleChange, values, touched, errors, setFieldValue,
  } = props;

  const dateChange = (value) => {
    setFieldValue(name, Timestamp.fromDate(value.toDate()));
  };

  const numberChange = (event) => {
    setFieldValue(name, Number(event.target.value));
  };

  if (type === 'date') {
    const initMoment = values[name] ? moment(values[name].toDate()) : moment();

    return (
      <DesktopDatePicker
        label={name}
        value={initMoment}
        inputFormat="DD/MM/YYYY"
        onChange={dateChange}
        name={name}
        components={{
          TextField,
        }}
      />

    );
  } if (type === 'number') {
    return (
      <TextField
        type="number"
        name={name}
        onChange={numberChange}
        onBlur={numberChange}
        value={values[name]}
        label={name}
        error={touched[name] && Boolean(errors[name])}
        helperText={errors[name] && touched[name] && errors[name]}
        inputProps={{ step: 1 }}
        style={{ maxWidth: 200 }}
      />
    );
  }
  return (
    <TextField
      style={{ maxWidth: 200 }}
      size="large"
      fullWidth
      name={name}
      onChange={handleChange}
      value={values[name]}
      label={name}
      error={touched[name] && Boolean(errors[name])}
      helperText={errors[name] && touched[name] && errors[name]}
    />
  );
}

import React, { useState } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  doc, getDoc, writeBatch,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';
import {
  getStorage, ref as storageRef,
} from 'firebase/storage';
import { extractFilesFromFormikValues, processAndReplaceFiles, uploadWithTimeout } from 'components/utils';
import AgreeCheckMark from 'components/customInputs/AgreeCheckMark';
import InputWithTooltip from 'components/customInputs/InputWithTooltip';
import InputFileUpload from 'components/customInputs/InputFileUpload';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { validationSchema } from './validationSchema';
import ScrollToFieldError from '../ScrollToFieldError';
import SavedFormsDialog, { saveFormToFirestore } from '../sellerForm/SavedFormsDialog';

export default function SharedSellerSaleFormalitiesForm({
  initialValues, mode, contract,
}) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const [pageStatus, setPageStatus] = useState('initial');
  const storage = getStorage();
  const [, globalActions] = useGlobal();
  const navigate = useNavigate();
  const isReadOnly = mode === 'readonly';
  const analytics = getAnalytics();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const checkoutSession = httpsCallable(functions, 'stripeCheckoutSession');
  const { currentUser: user } = useAuth();
  const [scrollToErrorActive, setScrollToErrorActive] = useState(false);
  const [savedFormsDialogOpen, setSavedFormsDialogOpen] = useState(false);

  const handleFormSubmit = () => {
    setScrollToErrorActive(true);
    if (mode === 'create') {
      saveFormToFirestore('savedSellerSaleFormalitiesForms', formik.values, user.uid, firestore);
    }
    formik.submitForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      try {
        sendSaleFormalitiesForm();
      } catch (error) {
        globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
        throw error;
      }
    },
  });

  const uploadFiles = async (files, basePath) => {
    const uploadPromises = files.map(async (fileData) => {
      const fileRef = storageRef(storage, `${basePath}/${fileData.path}/${fileData.file.name}`);
      await uploadWithTimeout(t, fileRef, fileData.file);
    });
    return Promise.all(uploadPromises);
  };

  const sendSaleFormalitiesForm = async () => {
    setPageStatus('loading');
    try {
      const allFiles = [];

      extractFilesFromFormikValues(formik.values, allFiles);

      const saleDocId = contract.id;
      const saleDocPath = `autonoomContracts/${saleDocId}`;

      let valuesToStore = await processAndReplaceFiles(formik.values, allFiles, saleDocPath);
      valuesToStore = { ...valuesToStore };

      // console.log('FINAL VALUES: ', valuesToStore);

      const batch = writeBatch(firestore);

      const autonoomContractDocRef = doc(firestore, 'autonoomContracts', saleDocId);
      if (mode === 'create') {
        batch.update(autonoomContractDocRef, {
          state: 'sellerSaleFormalitiesCompleted',
          sellerSaleFormalitiesForm: {
            submitDate: new Date(),
            values: valuesToStore,
          },
        });
      } else {
        batch.update(autonoomContractDocRef, {
          sellerSaleFormalitiesForm: {
            updateDate: new Date(),
            values: valuesToStore,
          },
        });
      }

      await Promise.all([
        uploadFiles(allFiles, saleDocPath),
      ]);

      await batch.commit();
      if (mode === 'create') {
        await handleStripeCheckout();
      } else {
        globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', displayDuration: 2000 });
        const docSnapshot = await getDoc(autonoomContractDocRef);

        navigate(-1);
        navigate('/myContracts/contract', {
          replace: true,
          state: { contract: { ...docSnapshot.data(), id: docSnapshot.id } },
        });
      }
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err}`, severity: 'error' });
      throw err;
    } finally {
      setPageStatus('done');
    }
  };

  const handleStripeCheckout = async () => {
    logEvent(analytics, 'clicked_checkout_session', {
      appName: 'Autonoom Web',
    });
    try {
      const result = await checkoutSession({
        fbu: user.uid,
        cid: contract.id,
      });
      window.location.href = result.data.url;
    } catch (error) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
    } finally {
      setPageStatus('done');
    }
  };

  if (!contract) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 2,
        width: '90vw',
        maxWidth: 800,
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <ScrollToFieldError
        formik={formik}
        scrollToErrorActive={scrollToErrorActive}
        setScrollToErrorActive={setScrollToErrorActive}
        formBaseKey="sellerSaleFormalitiesForm"
        orderedKeys={[]}
      />

      <Typography sx={{ fontWeight: 'bold' }}>
        {`${t('vehicle_sale_contract')}\n${t('vehicle_sale_form_steps.step3')}`}
      </Typography>
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="contractConcludedAccordingToTheFrenchLaw"
        label={t('sellerSaleFormalitiesForm.contractConcludedAccordingToTheFrenchLaw')}
        extraText={t('sellerSaleFormalitiesForm.contractConcludedAccordingToTheFrenchLawExtra')}
      />
      <Typography>{t('sellerSaleFormalitiesForm.furnishTheFollowing')}</Typography>
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="keys"
        label={t('sellerSaleFormalitiesForm.keys')}
        extraText={t('sellerSaleFormalitiesForm.keysExtra')}
      />
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="registrationCertificate"
        label={t('sellerSaleFormalitiesForm.registrationCertificate')}
        extraText={t('sellerSaleFormalitiesForm.registrationCertificateExtra')}
      />
      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="technicalControlReport"
        label={t('sellerSaleFormalitiesForm.technicalControlReport')}
        extraText={t('sellerSaleFormalitiesForm.technicalControlReportExtra')}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={(
          <Trans
            i18nKey="sellerSaleFormalitiesForm.pledgeCertificateDocumentInfo"
            components={{ 1: <strong /> }}
          />
        )}
        input={(
          <InputFileUpload
            isReadOnly={isReadOnly}
            fileBaseName={t('fileName.pledgeCertificateDocument')}
            formik={formik}
            field="pledgeCertificateDocument"
            label={`${t('sellerSaleFormalitiesForm.pledgeCertificateDocument')} : `}
            accept="image/jpeg,image/png,application/pdf"
          />
          )}
      />

      <InputWithTooltip
        addedStyle={{ flexGrow: 1 }}
        message={(
          <Trans
            i18nKey="sellerSaleFormalitiesForm.europeanConformityCertificateInfo"
            components={{ 1: <strong /> }}
          />
        )}
        input={(
          <InputFileUpload
            isReadOnly={isReadOnly}
            fileBaseName={t('fileName.europeanConformityCertificate')}
            formik={formik}
            field="europeanConformityCertificate"
            label={`${t('sellerSaleFormalitiesForm.europeanConformityCertificate')} : `}
            accept="image/jpeg,image/png,application/pdf"
          />
          )}
      />

      <AgreeCheckMark
        isReadOnly={isReadOnly}
        formik={formik}
        field="knowledgeNonPayment"
        label={t('sellerSaleFormalitiesForm.knowledgeNonPayment')}
        extraText={t('sellerSaleFormalitiesForm.knowledgeNonPaymentExtra')}
      />

      {mode === 'create' && (
        <LoadingButton loading={pageStatus === 'loading'} onClick={handleFormSubmit} variant="contained">
          {t('continue_to_payment')}
        </LoadingButton>
      )}
      {mode === 'edit' && (
        <LoadingButton loading={pageStatus === 'loading'} onClick={handleFormSubmit} variant="contained">
          {t('modify')}
        </LoadingButton>
      )}
      {mode === 'create' && (
        <SavedFormsDialog
          open={savedFormsDialogOpen}
          setOpen={setSavedFormsDialogOpen}
          formik={formik}
          docName="savedSellerSaleFormalitiesForms"
        />
      )}

    </Box>
  );
}

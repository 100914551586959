import React, { useEffect, useState } from 'react';
import {
  Box, AppBar, Toolbar, IconButton,
} from '@mui/material';
import {
  BrowserRouter,
  useLocation,
} from 'react-router-dom';
import useGlobal from 'global-state/store';
import AutoRoutes from 'components/AutoRoutes';
import { useTranslation } from 'react-i18next';
import Cookies from 'components/cookies/Cookies';
import { menuPaths } from 'components/utils';
import TopToolbar from 'components/TopToolbar';
import MenuIcon from '@mui/icons-material/Menu';
import { MenuProvider } from 'menu-actions/MenuContext';
import { SignInProvider } from 'useSignIn/SingInContext';
import { doc, getDoc } from 'firebase/firestore';
import { useAuth } from 'firebaseHooks/AuthContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import Footer from './Footer';
import GlobalSnackbar from './GlobalSnackbar';
import ScrollToTop from './ScrollToTop';
import SignInDialog from './signin/SignInDialog';
import OurDrawer from './drawer/OurDrawer';

export default function Home() {
  const [globalState, globalActions] = useGlobal();
  const { currentUser: user, initializing: userInitializing } = useAuth();
  const db = useFirestore();

  useEffect(() => {
    // eslint-disable-next-line no-promise-executor-return
    const sleep = (ms) => new Promise((r) => setTimeout(r, ms));
    async function getUser(uid) {
      const userRef = doc(db, 'users', uid);
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function getUserPublicData(uid) {
      const userRef = doc(db, 'users', uid);
      const firestoreUser = await getDoc(userRef);
      return firestoreUser;
    }
    async function fetchMultipleTimesUser(uid) {
      let firestoreUser = await getUser(uid);
      if (!firestoreUser.exists()) {
        await sleep(1000);
        firestoreUser = await getUser(uid);
        if (!firestoreUser.exists()) {
          await await sleep(2000);
          firestoreUser = await getUser(uid);
        }
      }
      return firestoreUser;
    }

    async function setupGlobalStateFromUser() {
      globalActions.setUserStatus('loading');
      await fetchMultipleTimesUser(user.uid);
      const firestoreUserPublicData = await getUserPublicData(user.uid);
      const userPublicData = firestoreUserPublicData.data();

      globalActions.setUserRoles(userPublicData.autonoomRoles);

      return globalActions.setUserStatus('setupDone');
    }

    if (!userInitializing && globalState.userStatus === 'initial') {
      if (user?.uid) {
        setupGlobalStateFromUser();
      } else {
        globalActions.setUserStatus('notSignedIn');
      }
    }
  }, [db, globalActions, globalState.userStatus, user?.uid, userInitializing]);

  // if (!['notSignedIn', 'setupDone'].includes(globalState.userStatus)) {
  //   return <LoadingPage />;
  // }

  return (
    <SignInProvider>
      <MenuProvider app={<ResponsiveDrawer />} />
    </SignInProvider>
  );
}

function ResponsiveDrawer(props) {
  const [, globalActions] = useGlobal();
  const { t } = useTranslation();
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (
      event
      && event.type === 'keydown'
      && (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  return (
    <BrowserRouter>
      <Box sx={{
        display: 'flex',
      }}
      >
        <ScrollToTop />
        <GlobalSnackbar />
        <AppBar
          position="fixed"
          elevation={1}
          sx={{
            width: '100%',
          }}
          color="inherit"
        >
          <Toolbar>
            <MenuButton toggleDrawer={toggleDrawer} />
            <TopToolbar />
          </Toolbar>
        </AppBar>
        <OurDrawer open={drawerOpen} toggleDrawer={toggleDrawer} />
        <Box
          component="main"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '100vw',
            alignItems: 'center',
            pt: { xs: 10, md: 12 },
          }}
        >
          <Box sx={{
            minHeight: '75vh',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
          >
            <AutoRoutes />
          </Box>
          <Footer />
          <Cookies
            cookieName="agreed-to-cookies-on-autonoom"
            t={t}
            globalActions={globalActions}
          />
        </Box>
        <SignInDialog />
      </Box>
    </BrowserRouter>
  );
}

function MenuButton({ toggleDrawer }) {
  const location = useLocation();
  if (menuPaths(location)) {
    return (
      <IconButton
        edge="start"
        sx={{ mr: 2 }}
        color="inherit"
        aria-label="menu"
        onClick={toggleDrawer(true)}
      >
        <MenuIcon />
      </IconButton>
    );
  }
  return null;
}

import React, { useState } from 'react';
import {
  Box, Typography,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useFormik } from 'formik';
import {
  collection, doc, getDoc, writeBatch,
} from 'firebase/firestore';
import { LoadingButton } from '@mui/lab';
import useGlobal from 'global-state/store';
import {
  getStorage, ref as storageRef,
} from 'firebase/storage';
import { extractFilesFromFormikValues, processAndReplaceFiles, uploadWithTimeout } from 'components/utils';
import emailTemplate from 'components/emails/EmailTemplate';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';
import { validationSchema } from './validationSchema';
import SaleDetails from './fields/SaleDetails';
import SaleFormalities from './fields/SaleFormalities';
import BuyerDetails from './fields/BuyerDetails';
import Vehicle from './fields/Vehicle';
import ScrollToFieldError from '../ScrollToFieldError';
import AcquirerPresentDialog from './AcquirerPresentDialog';
import SavedFormsDialog, { saveFormToFirestore } from '../sellerForm/SavedFormsDialog';

export default function SharedBuyerForm({
  mode, initialValues, contract,
}) {
  const { t } = useTranslation();
  const firestore = useFirestore();
  const [pageStatus, setPageStatus] = useState('initial');
  const storage = getStorage();
  const [, globalActions] = useGlobal();
  const { currentUser: user } = useAuth();
  const navigate = useNavigate();
  const isReadOnly = mode === 'readonly';
  const [scrollToErrorActive, setScrollToErrorActive] = useState(false);
  const [initialDialogOpen, setInitialDialogOpen] = useState(mode === 'create' && contract.bothPartiesPresent);
  const [savedFormsDialogOpen, setSavedFormsDialogOpen] = useState(false);

  const handleFormSubmit = () => {
    setScrollToErrorActive(true);
    if (mode === 'create') {
      saveFormToFirestore('savedAcquirerForms', formik.values, user.uid, firestore);
    }
    formik.submitForm();
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema(t),
    onSubmit: (values) => {
      try {
        sendBuyerForm();
      } catch (error) {
        globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} ${error}`, severity: 'error' });
        throw error;
      }
    },
  });

  const uploadFiles = async (files, basePath) => {
    const uploadPromises = files.map(async (fileData) => {
      const fileRef = storageRef(storage, `${basePath}/${fileData.path}/${fileData.file.name}`);
      await uploadWithTimeout(t, fileRef, fileData.file);
    });
    return Promise.all(uploadPromises);
  };

  const sendBuyerForm = async () => {
    setPageStatus('loading');
    try {
      const allFiles = [];

      extractFilesFromFormikValues(formik.values, allFiles);

      const saleDocId = contract.id;
      const saleDocPath = `autonoomContracts/${saleDocId}`;

      let valuesToStore = await processAndReplaceFiles(formik.values, allFiles, saleDocPath);
      valuesToStore = { ...valuesToStore };

      const prettyPrinted = JSON.stringify(valuesToStore, null, 2);
      console.log('FINAL VALUES: ', prettyPrinted);

      const batch = writeBatch(firestore);

      const autonoomContractDocRef = doc(firestore, 'autonoomContracts', saleDocId);
      let contractData;
      if (mode === 'create') {
        contractData = {
          state: 'acquierSubmittedForm',
          acquirer: user.uid,
          acquirerForm: {
            submitDate: new Date(),
            values: valuesToStore,
          },
        };
        batch.update(autonoomContractDocRef, contractData);
      } else {
        contractData = {
          acquirerForm: {
            updateDate: new Date(),
            values: valuesToStore,
          },
        };
        batch.update(autonoomContractDocRef, contractData);
      }

      await Promise.all([
        uploadFiles(allFiles, saleDocPath),
      ]);

      if (!contract.bothPartiesPresent) {
        const baseUrl = window.location.origin;
        const path = '/myContracts';
        const link = `${baseUrl}${path}`;

        const messageHtml1 = emailTemplate(` <p>${t('contract.acquirer_completed_part_mail_content', {
          vehicleName: contract.ownerForm.values.vehicle.model,
          link: ` <p>
          <a href="${link}"
             style="
               display: inline-block;
               padding: 10px 20px;
               background-color: #14c7bf;
               color: white;
               text-decoration: none;
               border-radius: 5px;
               font-weight: bold;
             ">
              Terminer la création du contrat
          </a>
        </p>`,
          linkText: link,
        })}</p>`);

        const messageHtml2 = emailTemplate(` <p>${t('contract.acquirer_updated_part_mail_content', {
          vehicleName: contract.ownerForm.values.vehicle.model,
          link: ` <p>
          <a href="${link}"
             style="
               display: inline-block;
               padding: 10px 20px;
               background-color: #14c7bf;
               color: white;
               text-decoration: none;
               border-radius: 5px;
               font-weight: bold;
             ">
              Terminer la création du contrat
          </a>
        </p>`,
          linkText: link,
        })}</p>`);

        const mailDocRef = doc(collection(firestore, 'mail'));
        if (mode === 'create') {
          batch.set(mailDocRef, {
            to: contract.ownerEmail,
            message: {
              subject: t('contract.acquirer_completed_part_mail_title'),
              html: messageHtml1,
            },
          });
        } else {
          batch.set(mailDocRef, {
            to: contract.ownerEmail,
            message: {
              subject: t('contract.acquirer_updated_part_mail_title'),
              html: messageHtml2,
            },
          });
        }
      }

      await batch.commit();

      if (contract.bothPartiesPresent && mode === 'create') {
        const newContract = {
          ...contract,
          ...contractData,
          id: saleDocId,
        };
        navigate('/myContracts/contract/saleFormalitiesForm', {
          replace: true,
          state: { mode: 'create', contract: newContract },
        });
      } if (mode === 'edit') {
        globalActions.setSnackbarMessage({ message: t('success'), severity: 'success', displayDuration: 2000 });
        const docSnapshot = await getDoc(autonoomContractDocRef);

        navigate(-1);
        navigate('/myContracts/contract', {
          replace: true,
          state: { contract: { ...docSnapshot.data(), id: docSnapshot.id } },
        });
      } else {
        navigate('/myContracts', { replace: true });
      }
    } catch (err) {
      globalActions.setSnackbarMessage({ message: `${t('unexpected_error')} : ${err}`, severity: 'error' });
      throw err;
    } finally {
      setPageStatus('done');
    }
  };

  if (!contract) {
    return null;
  }

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        gap: 10,
        mt: 2,
        width: '90vw',
        maxWidth: 800,
      }}
      component="form"
      onSubmit={formik.handleSubmit}
    >
      <ScrollToFieldError
        formik={formik}
        scrollToErrorActive={scrollToErrorActive}
        setScrollToErrorActive={setScrollToErrorActive}
        formBaseKey="buyerForm"
        orderedKeys={[]}
      />

      <Typography sx={{ fontWeight: 'bold' }}>
        {`${t('vehicle_sale_contract')}\n${t('vehicle_sale_form_steps.step2')}`}
      </Typography>

      <AcquirerPresentDialog open={initialDialogOpen} setOpen={setInitialDialogOpen} />

      <SaleDetails
        isReadOnly={isReadOnly}
        formik={formik}
        ownerFormValues={contract.ownerForm.values}
        mode={mode}
        bothPartiesPresent={contract.bothPartiesPresent}
      />
      <Vehicle
        isReadOnly={isReadOnly}
        formik={formik}
      />
      <SaleFormalities
        isReadOnly={isReadOnly}
        formik={formik}
      />
      <BuyerDetails
        isReadOnly={isReadOnly}
        formik={formik}
      />

      {mode === 'create' && (
      <LoadingButton loading={pageStatus === 'loading'} onClick={handleFormSubmit} variant="contained">
        {t('continue')}
      </LoadingButton>
      )}
      {mode === 'edit' && (
      <LoadingButton loading={pageStatus === 'loading'} onClick={handleFormSubmit} variant="contained">
        {t('modify')}
      </LoadingButton>
      )}
      {mode === 'create' && (
        <SavedFormsDialog
          open={savedFormsDialogOpen}
          setOpen={setSavedFormsDialogOpen}
          formik={formik}
          docName="savedAcquirerForms"
        />
      )}

    </Box>
  );
}

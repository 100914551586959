import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import LoadingButton from '@mui/lab/LoadingButton';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { getAnalytics, logEvent } from 'firebase/analytics';
import { httpsCallable } from 'firebase/functions';
import useGlobal from 'global-state/store';
import { doc, getDoc } from 'firebase/firestore';
import { Box } from '@mui/material';
import { useFunctions } from 'firebaseHooks/FunctionsContext';
import { useFirestore } from 'firebaseHooks/FirestoreContext';
import { useAuth } from 'firebaseHooks/AuthContext';

export default function ActivateCode() {
  const { t } = useTranslation();
  const analytics = getAnalytics();
  const functions = useFunctions();
  functions.region = 'europe-west1';
  const [activating, setActivating] = useState(false);
  const { currentUser: user } = useAuth();
  const [, globalActions] = useGlobal();
  const db = useFirestore();

  const activateCodeCall = httpsCallable(functions, 'activateCodeCall');

  async function refreshUser() {
    const userRef = doc(db, 'users', user?.uid);
    const firestoreUser = await getDoc(userRef);
    const userData = firestoreUser.data();
    if (userData) {
      globalActions.setUserRoles(userData.autonoomRoles);
    }
  }

  return (
    <Formik
      initialValues={{ code: '' }}
      validate={(values) => {
        const errors = {};
        if (!values.code) {
          errors.code = t('required');
        } else if (!/^[A-Z0-9a-z-]*$/i.test(values.code)) {
          errors.code = t('codes.code_invalid');
        }
        return errors;
      }}
      onSubmit={async (values, { setSubmitting }) => {
        try {
          setActivating(true);
          await activateCodeCall({ codeUid: values.code, userUid: user?.uid });
          globalActions.setSnackbarMessage({ message: t('codes.success'), severity: 'success', displayDuration: 2000 });
          refreshUser();
        } catch (error) {
          globalActions.setSnackbarMessage({ message: error.message, severity: 'error' });
          setSubmitting(false);
          logEvent(analytics, 'code_activation_error', {
            appName: 'Autonoom Web',
            error_message: error.message,
          });
        } finally {
          setActivating(false);
        }
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => (
        <Box
          component="form"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            gap: 1,
          }}
          onSubmit={handleSubmit}
        >
          <TextField
            size="small"
            fullWidth
            name="code"
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.code}
            label={t('codes.enter_code')}
            error={touched.code && Boolean(errors.code)}
            helperText={errors.code && touched.code && errors.code}
          />
          <LoadingButton
            size="medium"
            loading={activating}
            variant="outlined"
            color="secondary"
            type="submit"
            disabled={isSubmitting}
          >
            {t('codes.activate')}
          </LoadingButton>
        </Box>
      )}
    </Formik>
  );
}

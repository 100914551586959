import { useState, useEffect, useCallback } from 'react';
import {
  collection, query, getDocs, onSnapshot,
} from 'firebase/firestore';
import { useFirestore } from './FirestoreContext';

const useFirestoreCollectionData = (collectionPath, options = {}) => {
  const firestore = useFirestore();
  const [data, setData] = useState([]);
  const [loadingState, setLoadingState] = useState('initial');
  const [error, setError] = useState(null);

  const fetchDataOnce = useCallback(async () => {
    setLoadingState('loading');
    if (typeof collectionPath !== 'string') {
      const errorMessage = `Invalid collectionPath: expected a string but got ${typeof collectionPath}`;
      setLoadingState('error');
      throw new Error(errorMessage);
    }
    try {
      const collectionRef = collection(firestore, collectionPath);
      const querySnapshot = await getDocs(collectionRef);
      const docsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setData(docsData);
      setLoadingState('success');
    } catch (err) {
      setError(err);
      setLoadingState('error');
      throw err;
    }
  }, [collectionPath, firestore]);

  const subscribeToUpdates = useCallback(() => {
    if (typeof collectionPath !== 'string') {
      const errorMessage = `Invalid collectionPath: expected a string but got ${typeof collectionPath}`;
      setLoadingState('error');
      throw new Error(errorMessage);
    }

    try {
      const collectionRef = collection(firestore, collectionPath);
      const q = query(collectionRef);
      const unsubscribe = onSnapshot(q, (snapshot) => {
        const docs = snapshot.docs.map((doc) => ({
          ...doc.data(),
          [options.idField || 'id']: doc.id,
        }));
        setData(docs);
        setLoadingState('success');
      }, (err) => {
        setError(err);
        setLoadingState('error');
      });

      return unsubscribe;
    } catch (err) {
      setError(err);
      setLoadingState('error');
      throw err;
    }
  }, [collectionPath, firestore, options.idField]);

  useEffect(() => {
    let unsubscribe;
    if (loadingState === 'initial') {
      fetchDataOnce().then(() => {
        unsubscribe = subscribeToUpdates();
      });
    }
    return () => unsubscribe && unsubscribe();
  }, [collectionPath, fetchDataOnce, subscribeToUpdates, firestore, loadingState]);

  return { data, loadingState, error };
};

export default useFirestoreCollectionData;
